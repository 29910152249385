import { useEffect, useState } from 'react';

import { IconTopArrow } from '@/assets/svgs/common';
import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { usePageContext } from '@/components/common/PageContext';

import * as styles from './TopButton.styles';

export const TopButton = (): ReactJSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const scrollHandler = () => {
      setIsVisible(window.scrollY > 0);
    };

    window.addEventListener('scroll', scrollHandler, { passive: true });
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const onScrollToTop = () => window.scrollTo({ top: 0 });

  const { screenName, params } = usePageContext();
  return (
    <TrackClickEvent screenName={screenName} target="top_button" params={params}>
      <button css={styles.topButtonStyle} type="button" onClick={onScrollToTop} data-is-hidden={!isVisible}>
        <IconTopArrow aria-label="맨 위로 스크롤" />
      </button>
    </TrackClickEvent>
  );
};
