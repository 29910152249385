import { SerializedStyles } from '@emotion/react';
import Link from 'next/link';
import { ReactNode, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { IconGNBCategory } from '@/assets/svgs/system';
import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { TrackViewEvent } from '@/components/common/EventClient/TrackViewEvent';
import {
  HorizontalScrollContainer,
  HorizontalScrollContainerController,
} from '@/components/common/HorizontalScrollContainer';
import { usePageContext } from '@/components/common/PageContext';
import { RIDITheme } from '@/components/styles/themes';
import { currentNavigationRouteSelector } from '@/features/global/globalNavigationBar/navigation/navigationSlice';
import { Navigation } from '@/models/backendsApi/v2/Navigation/NavigationType';

import * as styles from './GenreHomeTab.styles';

interface GenreHomeTabItemsProps {
  items: Navigation[];
  itemCss?: (theme: RIDITheme) => SerializedStyles;
  itemActiveCss?: (theme: RIDITheme) => SerializedStyles;
  selectedId: number;
  target: string;
  className?: string;
  children?: ReactNode;
}

export const GenreHomeTabItems = ({
  items,
  itemCss,
  itemActiveCss,
  selectedId,
  target,
  className,
  children,
}: GenreHomeTabItemsProps): ReactJSX.Element => {
  const pageContext = usePageContext();
  const containerControllerRef = useRef<HorizontalScrollContainerController>(null);
  const childrenRefs = useRef<Record<number, HTMLElement>>({});
  const setChildRef = (itemId: number) => (element: HTMLElement | null) => {
    if (!element) {
      delete childrenRefs.current[itemId];
      return;
    }

    childrenRefs.current[itemId] = element;
  };

  useEffect(() => {
    if (!containerControllerRef.current) {
      return;
    }

    const activeCategoryElement = childrenRefs.current[selectedId ?? 0];
    if (!activeCategoryElement) {
      return;
    }

    containerControllerRef.current.focus(activeCategoryElement);
  }, [selectedId]);

  return (
    <HorizontalScrollContainer contentCss={styles.scrollWrapperStyle} ref={containerControllerRef}>
      <ul className={className} css={styles.defaultItemsStyle}>
        {items.map((item, index) => {
          const eventParams = { ...pageContext.params, path: item.path, title: item.title, index };
          return (
            <TrackViewEvent
              key={`navigation-${item.id}`}
              screenName={pageContext.screenName}
              target={target}
              params={eventParams}
              ref={setChildRef(item.id)}>
              <li css={styles.defaultItemContainerStyle}>
                <TrackClickEvent screenName={pageContext.screenName} target={target} params={eventParams}>
                  <Link legacyBehavior href={item.path} key={item.id} passHref>
                    <a href={item.path} css={[itemCss, item.id === selectedId && itemActiveCss]}>
                      {item.title}
                    </a>
                  </Link>
                </TrackClickEvent>
              </li>
            </TrackViewEvent>
          );
        })}
        {children}
      </ul>
    </HorizontalScrollContainer>
  );
};

interface GenreHomeTabProps {
  className?: string;
  children?: ReactNode;
}

export const GenreHomeTab = ({ className, children }: GenreHomeTabProps): ReactJSX.Element => {
  const currentNavigationRoute = useSelector(currentNavigationRouteSelector);

  // prettier-ignore
  const [/* selectedRootNavigation */, selectedGlobalNavigation, selectedTabItem, selectedSubTabItem] =
    currentNavigationRoute ?? [];

  const tabItems = selectedGlobalNavigation?.children;
  const subTabItems = selectedTabItem?.children;

  const pageContext = usePageContext();

  return (
    <div css={styles.wrapperStyle}>
      <div css={styles.tabWrapperStyle} className={className}>
        {tabItems && selectedTabItem && (
          <GenreHomeTabItems
            css={styles.tabStyle}
            items={tabItems}
            itemCss={styles.tabItemStyle}
            itemActiveCss={styles.tabItemActiveStyle}
            selectedId={selectedTabItem.id}
            target="tab">
            <li css={styles.allCategoriesStyle} key="all-categories">
              <TrackClickEvent screenName={pageContext.screenName} target="all_categories" params={pageContext.params}>
                <a href="/category/list" css={styles.allCategoriesLinkStyle}>
                  <IconGNBCategory css={styles.allCategoriesIconStyle} />
                  <span css={styles.allCategoriesTextStyle}>전체 카테고리</span>
                </a>
              </TrackClickEvent>
            </li>
          </GenreHomeTabItems>
        )}

        <hr css={styles.tabDividerStyle} />

        {subTabItems && selectedSubTabItem && (
          <GenreHomeTabItems
            css={styles.subTabStyle}
            items={subTabItems}
            itemCss={styles.subTabItemStyle}
            itemActiveCss={styles.subTabItemActiveStyle}
            selectedId={selectedSubTabItem.id}
            target="subtab"
          />
        )}
        {children}
      </div>
    </div>
  );
};
