import { css, SerializedStyles } from '@emotion/react';

import { pageFooterStyle } from '@/components/styles';
import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const hiddenH1Style = css`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
`;

export const globalOverscrollStyle = (theme: RIDITheme): SerializedStyles => css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      html,
      body,
      #__next {
        background-color: ${theme.colors.black};
      }
    `,
  )};
`;

export const mainStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.bgBase};
  overflow: hidden;
  position: relative;
`;

export const footerStyle = css`
  ${pageFooterStyle};
  position: relative;
`;
