import { css, SerializedStyles } from '@emotion/react';
import { scrollBarHidden } from '@ridi-web/design-system/Styles';

import { pageContainerStyle } from '@/components/styles';
import { BreakPoint, orBelow } from '@/components/styles/media';
import { a11y } from '@/components/styles/reset';
import { RIDITheme } from '@/components/styles/themes';

export const wrapperStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.bgBase};
  position: relative;
`;

export const tabWrapperStyle = css`
  ${pageContainerStyle};
  padding-left: 0;
  padding-right: 0;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-left: 0;
      padding-right: 0;
      margin-top: -10px;
    `,
  )};
`;

export const scrollWrapperStyle = css`
  display: block;
`;

export const defaultItemsStyle = css`
  display: flex;
  ${scrollBarHidden};
`;

export const defaultItemContainerStyle = css`
  display: inline-flex;
  flex: 0 0 auto;
`;

export const tabDividerStyle = (theme: RIDITheme): SerializedStyles => css`
  height: 1px;
  background: ${theme.colors.grey100};

  border: none;
  margin: 0 30px;
  margin-top: -1px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-left: 0;
      margin-right: 0;
    `,
  )};
`;

export const tabStyle = css`
  padding: 0 18px;
  padding-right: 30px;
  overflow: auto;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0 8px;
    `,
  )};
`;

export const tabItemStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0 12px;
  padding-top: 14px;
  padding-bottom: 17px;

  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.01em;
  color: ${theme.colors.fillPrimary};
  transition: opacity 0.2s ease;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 0 8px;
      padding-top: 12px;
      padding-bottom: 13px;

      font-size: 16px;
      line-height: 19px;
    `,
  )};

  &:hover {
    opacity: 0.6;
  }
`;

export const tabItemActiveStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.blue};
  cursor: default;
  pointer-events: none;

  &:hover {
    opacity: 1;
  }
`;

export const allCategoriesStyle = css`
  display: inline-flex;
  align-items: center;
  margin-left: auto;
`;

export const allCategoriesLinkStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  align-items: center;
  color: ${theme.colors.fillPrimary};
  padding: 12px 8px;
  padding-right: 1px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 8px;
      padding-right: 8px;
    `,
  )};
`;

export const allCategoriesIconStyle = css`
  font-size: 26px;
  margin-right: 6px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 24px;
      margin-right: 0;
    `,
  )};
`;

export const allCategoriesTextStyle = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: -0.02em;

  ${orBelow(BreakPoint.DesktopSmall, a11y)};
`;

export const subTabStyle = css`
  padding: 7px 28px;
  overflow: auto;
  display: flex;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 12px;
      padding-bottom: 10px;
      padding-top: 8px;
    `,
  )};
`;

export const subTabItemStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 34px;
  border-radius: 40px;
  margin: 6px 2px;
  padding: 0 10px;

  color: ${theme.colors.fillPrimary};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.01em;

  transition: all 0.2s ease-in-out;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: 30px;
      margin: 0;
      font-size: 14px;
      line-height: 17px;
    `,
  )};

  &:hover {
    opacity: 0.4;
  }
`;

export const subTabItemActiveStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.blue};
  color: ${theme.colors.white};
  font-weight: 700;
  cursor: default;
  pointer-events: none;

  &:hover {
    opacity: 1;
  }
`;
